<!--安装服务费明细-->
<template>
  <div class="installationFeeDetail bgblue">
    <div class="top"></div>
    <div class="form">
      <el-form :model="form" label-width="90px">
        <el-row>
          <el-col :span="4"><el-form-item label="ERP单号:">
              <el-input v-model="form.erpcode" placeholder="请输入ERP单号"></el-input> </el-form-item></el-col>
          <el-col :span="4"><el-form-item label="金融单号:">
              <el-input v-model="form.financeCode" placeholder="请输入金融单号"></el-input> </el-form-item></el-col>
          <el-col :span="4"><el-form-item label="业务类型名称:">
              <el-input v-model="form.businessTypeName" placeholder="请输入金融单号"></el-input> </el-form-item></el-col>
          <!-- <el-col :span="4">
            <el-form-item label="业务类型:">
              <el-select v-model="explorationStatus.region" placeholder="请选择业务类型" style="width: 100%">
                <el-option v-for="item in explorationStatus" :key="item.region" :label="item.label"
                  :value="item.region">
                </el-option>
              </el-select> </el-form-item></el-col> -->
          <el-col :span="4"><el-form-item label="用户姓名:">
              <el-input v-model="form.userName" placeholder="请输入用户姓名"></el-input> </el-form-item></el-col>
          <el-col :span="5" class="mt5 ">
            <el-form-item>
              <el-button type="primary" size="small" @click="getData">
                <i class="el-icon-search"></i>查询</el-button>
              <el-button plain size="small">
                <i class="el-icon-refresh"></i>重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="bgwhite">
      <el-row>
        <el-button type="warning" plain size="small">
          导出</el-button>



      </el-row>
      <div class="table">
        <el-table :data="tableData" border height="calc(100vh - 250px)"
          :header-cell-style="{ background: '#f2f2f2', color: '#333' }">

          <el-table-column prop="business_type_name" label="业务类型" align="center">
          </el-table-column>
          <el-table-column prop="user_name" label="用户姓名" align="center">
          </el-table-column>
          <el-table-column prop="module_num" label="组件片数" align="center">
          </el-table-column>
          <el-table-column prop="agentName" label="并网日期" align="center" width="200px">
          </el-table-column>
          <el-table-column prop="unite_price" label="安装费单价(元/片)" width="150px" align="center">
          </el-table-column>
          <el-table-column prop="total_price" label="安装费合计(元)" width="150" align="center">
          </el-table-column>
          <el-table-column prop="pay_price" label="已支付安装费(元)" align="center">
          </el-table-column>
          <el-table-column prop="Topaid" label="待支付安装费(元)" align="center">
          </el-table-column>
          <el-table-column prop="Cumulative_payment" label="实际支付(元)" align="center">
          </el-table-column>
          <el-table-column prop=" " label="操作" align="center" fixed>
            <template v-slot="{ row }">
              <el-button type="text" class="btn-blue" @click="goDetail(row)">明细</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="params.currPage" :page-sizes="[20, 30, 50, 100]" :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      onShow: false,
      tableData: [],
      total: 0,
      params: {
        count: 0,
        currPage: 1,
        pageCount: 0,
        pageSize: 20,
      },
      form: {
        // dataStatus: 1,
      },
      roofType: [],
      roofNum: [],
      inverterSN: [],
      installType: [],
      explorationStatus: [],
      installationStatus: [],
      results: [],
      securityRejections: [],
      cooperationType: [],
      explorationArea: [],
      firm: [],
      dataType: [],
      securityDocument: [],
      wellPattern: [],
      value1: "",
      value2: "",
      currentPage4: 1,
      value: "",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    goDetail(row) {
      this.$router.push(
        `/expoleManageDetail?explorateCode=${row.explorateCode}`
      );
    },
    onSubmit() {
      console.log("submit!");
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.params.currPage = val;
      this.getData();
    },
    getData() {
      console.log('oooooooo')
      let data = {
        // condition: this.form,
        ...this.form,
        ...this.params,
      };
      this.$http.post("/houseAgentBill/installationServiceFeeList", data).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.count;
        } else {
          this.$message({
            type: 'error', // success error warning
            message: res.data.message,
            duration: 2000,
          })
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table td {
  padding: 0px !important;
}

::v-deep .el-input__icon {
  line-height: 21px !important;
}

::v-deep .el-table th {
  padding: 0px !important;
}

::v-deep .el-table {
  margin-top: 10px !important;
}

.installationFeeDetail {
  .el-form .el-row .el-col-4 {
    width: 19.5%;
  }

  .el-form-item__label {
    font-size: 13px;
  }

  margin-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;

  .el-date-editor .el-range__icon {
    line-height: 21px !important;
  }

  .top {
    margin-bottom: 10px;
  }

  .form {
    background-color: #fff;
    margin: 10px;
    padding: 10px 5px 0 5px;
    border: 1px solid #ececec;
    border-radius: 10px;
    box-shadow: 0px 0xp 10px #ccc;
  }

  .pagination {
    float: right;
  }

  .bgwhite {
    margin: 10px;
    padding: 10px;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ececec;
    border-radius: 10px;
  }

  // .el-button {
  //   padding: 0 10px;
  // }
}

::v-deep .el-table--small th {
  padding: 0 !important;
}
</style>
